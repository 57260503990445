import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../img/Icons';

export const AIButton = ({ text }) => (
    <button className="ai-button">
        <span className="ai-white">{icons['ai']()}</span>
        <span>{text}</span>
    </button>
);
AIButton.propTypes = {
    text: PropTypes.string.isRequired,
};
