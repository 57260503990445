import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import confirm from '../../FormControls/ConfirmModal/ConfirmModalService';
import icons from '../../../img/Icons';
import models from '../../../models';
import AddSavedSearchModal from './AddSavedSearchModal';
import { TableContext, TABLE_ACTION } from '../../FormControls/Table/TableContext';
import { reRunSearch } from '../utils';

const SavedSearchActions = ({
    queryParams,
    formattedItems,
    savedTitle,
    resultsCount,
    searchedURL,
    itemId,
}) => {
    const [editVisible, setEditVisible] = useState(false);

    const { dispatchTableState } = useContext(TableContext);

    const model = new models.SavedSearch();

    const setDirty = () => {
        dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });
    };

    const deleteItem = async () => {
        const confirmed = await confirm({
            title: 'Delete Saved Search',
            message: (
                <div>
                    <p>Are you sure you want to delete this Saved Search?</p>
                    <br />
                    <p className="saved-search-title">{savedTitle}</p>
                </div>
            ),
        });
        if (!confirmed) return false;

        try {
            await model.destroy(itemId);
            setDirty();
            toaster.success('Saved Search deleted');
        } catch (error) {
            console.error(error);
            toaster.error('Could not delete the saved search');
        }
    };

    return (
        <>
            <button
                className="btn-link rerun-search-btn"
                title="Rerun Saved Search"
                aria-label="rerun saved search"
                onClick={(event) =>
                    reRunSearch(event, model, searchedURL, savedTitle, itemId, setDirty)
                }
            >
                <span>{icons['rerun']()}</span>
            </button>
            <button
                className="btn-link"
                title="Edit Title of Saved Search"
                aria-label="edit title of saved search"
                onClick={() => setEditVisible(true)}
            >
                <span>{icons['edit']()}</span>
            </button>
            <button
                className="btn-link"
                title="Delete Saved Search"
                aria-label="delete saved search"
                onClick={() => deleteItem()}
            >
                <span>{icons['delete']()}</span>
            </button>

            {editVisible && (
                <AddSavedSearchModal
                    {...{
                        handleModal: () => setEditVisible(false),
                        showModal: editVisible,
                        resultsCount,
                        queryParams,
                        formattedItems,
                        savedTitle,
                        searchedURL,
                        setDirty,
                        itemId,
                    }}
                />
            )}
        </>
    );
};

export default SavedSearchActions;

SavedSearchActions.propTypes = {
    queryParams: PropTypes.object.isRequired,
    formattedItems: PropTypes.array.isRequired,
    savedTitle: PropTypes.string.isRequired,
    resultsCount: PropTypes.number.isRequired,
    searchedURL: PropTypes.string.isRequired,
    itemId: PropTypes.number.isRequired,
};
