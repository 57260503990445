export const SEARCH_MODE = {
    exact: 'Exact phrase',
    any: 'Any of the terms',
    all: 'All terms',
};
Object.freeze(SEARCH_MODE);

export const SEARCH_SCOPE = {
    exnotes: 'All text excluding notes',
    incnotes: 'All text including notes',
    titlesheadings: 'Titles and headings only',
};
Object.freeze(SEARCH_SCOPE);

export const SEARCH_DATE = {
    all: 'All Time',
    range: 'Custom Range',
    period: 'Custom Period',
};
Object.freeze(SEARCH_DATE);

export const SEARCH_STATE = {
    all: 'All',
    draft: 'Draft',
    ready: 'Ready',
    published: 'Published',
    retired: 'Retired',
};
Object.freeze(SEARCH_STATE);

export const filter_types = [
    { type: 'mode', label: 'Method', icon: 'searchMode' },
    { type: 'scope', label: 'Scope', icon: 'searchScope' },
    { type: 'date', label: 'Date', icon: 'date' },
    { type: 'state', label: 'State', icon: 'state' },
    { type: 'tt_option', label: 'Time Travel', icon: 'timeTravel' },
];
Object.freeze(filter_types);

export const SEARCH_SCOPE_TITLES = {
    exnotes: 'Editorial notes and footnotes etc will be excluded from the search.',
    incnotes: 'Editorial notes and footnotes etc will be included in the search.',
    titlesheadings:
        'Titles and Headings include section, part, schedule etc titles as well as the main document title.',
};
Object.freeze(SEARCH_SCOPE_TITLES);

export const SEARCH_MODE_TITLES = {
    exact: 'Exact phrase: A phrase can be single or multiple word(s) – no quote marks required',
    any: 'Any of the terms: Equivalent to an OR search – multiple words in quote marks are a term eg disclosure “pensionable age”',
    all: 'All terms within the same ‘section’: Equivalent to an AND search – this will find terms in close proximity',
};

Object.freeze(SEARCH_MODE_TITLES);
