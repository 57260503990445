import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { handleRadioChange } from '../utils';

export const Dates = ({
    additionalFilters,
    setAdditionalFilters,
    setDateErrors,
    dateErrors,
    queryParams,
}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedRadio, setSelectedRadio] = useState('all');
    const inputRef = useRef(null);

    useEffect(() => {
        if (queryParams) {
            if (queryParams.date === 'range') {
                setStartDate(queryParams.date_from.split('T')[0]);
                setEndDate(queryParams.date_to.split('T')[0]);
            }
            if (queryParams.date === 'period') {
                setAdditionalFilters((currFilters) => ({
                    ...currFilters,
                    date: queryParams.date,
                    date_last_value: queryParams.date_last_value,
                    date_last_units: queryParams.date_last_units,
                }));
            }
        }
    }, [queryParams]);

    const handleDateLastValueChange = (evt, setAdditionalFilters, setDateErrors) => {
        const value = evt.target.value.replace(/\D/g, '');
        setAdditionalFilters((currFilters) => ({
            ...currFilters,
            date_last_value: value,
        }));
        if (!additionalFilters.date_last_units) {
            setAdditionalFilters((currFilters) => ({
                ...currFilters,
                date_last_units: 'year',
            }));
        }
        setDateErrors({ range: false, period: false, futureRange: false });
    };

    useEffect(() => {
        setDateErrors((currErrors) => ({
            ...currErrors,
            range: false,
            futureRange: false,
        }));
        if (startDate || endDate) {
            setAdditionalFilters((currFilters) => ({
                ...currFilters,
                date_from: startDate ? new Date(startDate).toISOString() : '',
                date_to: endDate ? new Date(endDate).toISOString() : '',
                date: 'range',
            }));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (additionalFilters.date_last_value && additionalFilters.date !== 'period') {
            setAdditionalFilters((currFilters) => ({
                ...currFilters,
                date: 'period',
            }));
        }
        if (dateErrors.period) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                period: false,
            }));
        }
        if (additionalFilters.date_last_value) {
            inputRef.current.focus();
        }
    }, [additionalFilters.date_last_value]);

    useEffect(() => {
        if (additionalFilters.date === 'period' && inputRef.current) {
            inputRef.current.focus();
        }
        if (additionalFilters.date === 'range') {
            setSelectedRadio('range');
        } else if (additionalFilters.date === 'period') {
            setSelectedRadio('period');
        }
    }, [additionalFilters.date]);

    const handleFocus = (e) => {
        e.target.showPicker && e.target.showPicker(); // For browsers supporting `showPicker()`
        e.target.click(); // For other browsers
    };

    return (
        <div className="filter-group">
            <label>Time frame:</label>
            <Form.Group className="timeframe-group">
                <Form.Check
                    type="radio"
                    className={selectedRadio === 'all' ? 'selected-radio' : ''}
                    data-dd-action-name="Select all Dates"
                >
                    <Form.Check.Input
                        type="radio"
                        isValid
                        name="dates-group"
                        value="all"
                        onChange={(evt) => {
                            handleRadioChange(evt, 'date', setAdditionalFilters, setDateErrors);
                            setSelectedRadio('all');
                        }}
                        checked={additionalFilters.date === 'all'}
                        id="date-all"
                    />
                    <Form.Check.Label htmlFor="date-all">All time</Form.Check.Label>
                </Form.Check>
                <Form.Check
                    className={selectedRadio === 'range' ? 'selected-radio date' : ''}
                    data-dd-action-name="Select Date Range"
                >
                    <Form.Check.Input
                        type="radio"
                        isValid
                        name="dates-group"
                        value="range"
                        onChange={(evt) => {
                            handleRadioChange(evt, 'date', setAdditionalFilters, setDateErrors);
                            setSelectedRadio('range');
                        }}
                        checked={additionalFilters.date === 'range'}
                        id="date-range-input"
                    />
                    <Form.Check.Label htmlFor="date-range-input">
                        Date range
                        <InputGroup className="custom-period-group" id="start-date">
                            <InputGroup.Text className="date-label">Start</InputGroup.Text>
                            <Form.Control
                                type="date"
                                className="filter-date"
                                value={startDate}
                                onChange={(evt) => {
                                    setStartDate(evt.target.value);
                                }}
                                onFocus={handleFocus}
                                name="start-date"
                            />
                        </InputGroup>
                        <InputGroup className="custom-period-group" id="end-date">
                            <InputGroup.Text className="date-label">End</InputGroup.Text>
                            <Form.Control
                                type="date"
                                className="filter-date"
                                value={endDate}
                                onChange={(evt) => {
                                    setEndDate(evt.target.value);
                                }}
                                onFocus={handleFocus}
                                name="end-date"
                            />
                        </InputGroup>
                    </Form.Check.Label>
                </Form.Check>
                <Form.Check
                    className={selectedRadio === 'period' ? 'selected-radio date' : ''}
                    data-dd-action-name="Select Time Period"
                >
                    <Form.Check.Input
                        type="radio"
                        isValid
                        name="dates-group"
                        value="period"
                        onChange={(evt) => {
                            handleRadioChange(evt, 'date', setAdditionalFilters, setDateErrors);
                            setSelectedRadio('period');
                        }}
                        checked={additionalFilters.date === 'period'}
                        id="date-period-input"
                    />
                    <Form.Check.Label htmlFor="date-period-input">
                        Time period
                        <InputGroup className="custom-period-group">
                            <InputGroup.Text className="date-label">The last</InputGroup.Text>
                            <Form.Control
                                className={
                                    dateErrors.period
                                        ? 'custom-period date-period-error'
                                        : 'custom-period'
                                }
                                type="text"
                                ref={inputRef}
                                value={additionalFilters.date_last_value ?? ''}
                                name="customPeriodValue"
                                onChange={(evt) => {
                                    handleDateLastValueChange(
                                        evt,
                                        setAdditionalFilters,
                                        setDateErrors,
                                    );
                                }}
                                maxLength="3"
                                autoComplete="off"
                                aria-label="Enter number of days, months or years for custom period"
                            />
                            <Form.Control
                                size="sm"
                                as="select"
                                className="period-select"
                                value={additionalFilters.date_last_units}
                                onChange={(evt) =>
                                    handleRadioChange(
                                        evt,
                                        'date_last_units',
                                        setAdditionalFilters,
                                        setDateErrors,
                                    )
                                }
                                name="customPeriodUnits"
                                aria-label="Select day, month or year for custom period"
                            >
                                <option value="year" id="unit-year">
                                    Year(s)
                                </option>
                                <option value="month" id="unit-month">
                                    Month(s)
                                </option>
                                <option value="day" id="unit-day">
                                    Day(s)
                                </option>
                            </Form.Control>
                        </InputGroup>
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>
        </div>
    );
};

Dates.propTypes = {
    additionalFilters: PropTypes.object.isRequired,
    setAdditionalFilters: PropTypes.func.isRequired,
    dateErrors: PropTypes.object,
    setDateErrors: PropTypes.func.isRequired,
    queryParams: PropTypes.object,
};
